import { getAuth, onAuthStateChanged } from 'https://www.gstatic.com/firebasejs/9.1.2/firebase-auth.js';
import * as web3 from '/js/web3.min.js';

const auth = getAuth();
var currrentUser = null;
var userToken = null;
var addresses = {};


onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    currrentUser = user;
    console.log(user.email);

    currrentUser.getIdToken().then((token) => {  
        userToken = token;  
        $("body").data( "token", userToken);

        populateAddressesTable();
        isPremium();
    });

  } else {
    // User is signed out
    window.location.replace("login.html");
  }
});

function checkIsValidDomain(domain) { 
    var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/); 
    return domain.match(re);
} 

function checkIsValidAddress(domain) { 
    var re = new RegExp(/^0x[a-f0-9]{40}$/);
    return domain.match(re); 
} 

$("#btnAddAddress").click(async function() {
    let address = $("#inputAddress").val().trim().toLowerCase();
    var ethName = "";

    if (address == "") {
        alert("Address cannot be empty.");
        return;
    }

    $("#divLoading").toggle();

    if (!checkIsValidAddress(address)) {

        if (checkIsValidDomain(address)) {

            if (typeof window.ethereum !== 'undefined') {
                console.log('btnAddAddress: MetaMask is installed!');
            
                try {
                    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
                    const web3js = new Web3(ethereum);
                    const ens = web3js.eth.ens
                    let actualAddress = await ens.getAddress(address);
                    console.log(actualAddress);

                    ethName = address;
                    address = actualAddress;
                } catch (err) {
                    console.log(err.toString());
                    $("#divLoading").toggle();
                    alert("Could not resolve name: Either a network issue or a non-existent name was given.");
                    return;
                }

            } else {
                $("#divLoading").toggle();
                alert("Support for ENS names requires MetaMask connected.");
                return;
            }

        } 
    }

    if (addresses[address] != undefined) {
        $("#divLoading").toggle();
        alert("Address already in the list.");
        return;
    }

    console.log(address);
    console.log(ethName);

    $.post( "/add-address", { address: address, userToken: userToken, ethName: ethName }, null,"json")
    .done(function( data ) {
        if (data.status) {
            console.log("address added");
            $("#addressesTableDataRows").append(`<tr onclick='viewData("${address}", "${currrentUser.uid}")'><th scope='row'></th><td>${address}</td></tr>`);

            $.post( "/update-address", { address: address, userToken: userToken }, null,"json")
            .done(function( data ) {
                if (data.status) {
                    console.log("transactions updated");
                    
                }

                $("#divLoading").toggle();
            })
            .fail(function(error) {
                alert(error.toString());
            });


            $.post( "/get-tokens", { address: address, userToken: userToken }, null,"json")
            .done(function( data ) {
                if (data.status) {
                    console.log("tokens updated");
                    
                }
            })
            .fail(function(error) {
                alert(error.toString());
            });
        }
    })
    .fail(function(error) {
        alert(error.toString());
    });


});

function populateAddressesTable () {
    

        $.post( "/list-addresses", { userToken: userToken }, null,"json")
        .done(function( data ) {
            if (data.status) {

                $("#addressesTableDataRows").empty();

                console.log(data.data);

                for (var address in data.data.addresses) {
                    addresses[address] = true;

                    var name = data.data.addresses[address].addressName;

                    if (name == "" || name == undefined || name == null)
                        name = "Not Assigned";

                    if (data.data.premium) {
                        $("#addressesTableDataRows").append(`<tr><td>${name}</td><td><a onclick='viewData("${address}", "${currrentUser.uid}")' href="">${address}</a></td></tr>`);
                    } else {
                        $("#addressesTableDataRows").append(`<tr><td><span class="badge me-1 rounded-pill bg-success">Premium Feature</span> </td><td><a onclick='viewData("${address}", "${currrentUser.uid}")' href="">${address}</a></td></tr>`);
                    }

                 }

            }

            $("#divLoading").toggle();
        })
        .fail(function(error) {
            alert(error.toString());
        });


}

function isPremium() {

    $.post( "/is-premium", { userToken: userToken }, null,"json")
    .done(function( data ) {
        if (data.status) {
            if (data.data.premium) {
                $("#btnBuyPremium").html("PREMIUM USER");
                $("#btnBuyPremium").attr("disabled", true);

                $("#divUpgradeToPremium").hide();
            } else {
                $("#btnBuyPremium").html("BUY PREMIUM");
                $("#btnBuyPremium").attr("disabled", false);

                $("#divUpgradeToPremium").show();
            }
        }
    })
    .fail(function(error) {
        alert(error.toString());
    });
}

